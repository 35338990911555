! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);


jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Add dropdown class to main nav items
	$(".main-nav-li:has('.dropdown-list')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-link-a").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".main-nav-li").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".main-nav-li").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	$('.main-nav-li.has-dropdown').attr('data-ix',"dropdown-hover");

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-container").fitVids({ ignore: "nofit" });
	}

	// Adds .hovered class to parent links when hovered over the dropdown wrapper
	// You must manually create and style the .hover class in Custom Code
	var $nav = $('.dropdown-wrap');
	$nav.hover(
		function() { // hover in
			$(this).children('.nav-link-a').addClass('hovered');
		},
		function() { // hover out
			$(this).children('.nav-link-a').removeClass('hovered');
		}
	);

	// Submit form when search link is clicked
	$('.search-button').on('click',function() {
		$(this).parent().submit();
	})

	// When the window loads, note the scroll position
	// and add and remove classes accordingly
	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
	});

	/**
	 * ----------------------------------------------------------------------
	 * Webflow: Interactions: Init
	 */
	Webflow.require('ix').init([
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",selector: ".dropdown-list",descend: true,stepsA: [{opacity: 1,height: "auto",transition: "opacity 300ms ease-in-out 0ms, height 300ms ease-in-out 0ms"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 300ms ease-in-out 0ms, height 300ms ease-in-out 0ms"}]}]}},
		{slug: "search-toggle",name: "Search Toggle",value: {style: {},triggers: [{type: "click",selector: ".mobile-search-form-wrapper",stepsA: [{height: "auto",transition: "height 500ms ease-in-out-back 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease-in-out-back 0ms"}]}]}},
		{slug: "menu-closes-search",name: "Menu Closes Search",value: {style: {},triggers: [{type: "click",selector: ".search-form-wrapper",stepsA: [{height: "0px",transition: "height 500ms ease-in-out-back 0ms"}],stepsB: []}]}},
		{slug: "subnav-open",name: "Subnav Open",value: {style: {opacity: 0,height: "0px"},triggers: [{type: "load",stepsA: [{wait: 300},{opacity: 1,height: "auto",transition: "opacity 500ms ease 0ms, height 500ms ease 0ms"}],stepsB: []}]}},
		{slug: "cb-image-zoom",name: "CB Image Zoom",value: {style: {},triggers: [{type: "hover",selector: ".cb-card-img-wrap",descend: true,preserve3d: true,stepsA: [{transition: "height 200 ease 0, transform 400ms ease-out-quad 0",scaleX: 1.04,scaleY: 1.04,scaleZ: 1}],stepsB: [{transition: "transform 400ms ease-out-quad 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}}
	]);
});
